import * as React from 'react';

import { graphql } from 'gatsby';

import TemplatePage from 'components/pages/templates/TemplatePage';

const Template = ({
  data,
}) => (
  <TemplatePage
    title="Website Dashboard Templates | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/ecommerce-web-app-dashboards/"
    metaDescription="Looking to create your own dashboards for performance monitoring of your online store? Our e-commerce dashboard templates have everything you need."
    description="
    h2:Our e-commerce web app dashboard templates in dark mode have it all
    <br/>
    Looking to design your own e-commerce revenue and sales dashboards? Our e-commerce web app dashboard templates come with everything you could possibly need to get your own design off the ground. Packed with all the detail and user journeys you could possibly need, our <a:https://uizard.io/templates/>UI design templates</a> are ready to go right out of the box.
    <br/>
    h3:Adapt to suit your own online business before handing it off to a developer
    <br/>
    As with all our templates, our e-commerce web app dashboard templates are massively customizable, meaning you can use them as they come, or you can really adapt and customize your prototype to reflect your own branding or vision.
    <br/>
    h3:Design the perfect analytics suite for any online business
    <br/>
    Whether you are designing a web app for your own business or you want to create a brand-new e-commerce dashboard product to be used by your clients, Uizard templates offer you the perfect solution. <a:https://uizard.io/prototyping/>Rapidly prototype</a> your e-commerce dashboard web app designs today.
    "
    pages={[
      'All metrics summary page with top-level data',
      'Product summary pages',
      'Monthly/annual statement screens',
      'Refund overview and processing screens',
    ]}
    projectCode="lrmA0oOOp9TYpmj5WrmM"
    img1={data.image1.childImageSharp}
    img1alt="e-commerce web app dashboards dark mode cover"
    img2={data.image2.childImageSharp}
    img2alt="e-commerce web app dashboards dark mode context screen"
    img3={data.image3.childImageSharp}
    img3alt="e-commerce web app dashboards dark mode  detail screen"
    img4={data.image4.childImageSharp}
    img4alt="e-commerce web app dashboards dark mode overview screen"
    img5={data.image5.childImageSharp}
    img5alt="e-commerce web app dashboards dark mode summary screen"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/ecommerce-web-app-dashboards/ecommerce-dashboards-web-cover-v2.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/ecommerce-web-app-dashboards/ecommerce-dashboards-web-charts.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/ecommerce-web-app-dashboards/ecommerce-dashboards-web-lists.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/ecommerce-web-app-dashboards/ecommerce-dashboards-web-lists2.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/ecommerce-web-app-dashboards/ecommerce-dashboards-web-summary-v2.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
